<template>
  <div class="views-applyPage">

    <apply-demo class="apply-demo"></apply-demo>
    <img
      class="logo"
      :src="imgList.logo"
    />
    <div class="icon-group">
      <div
        class="icon-btn"
        @click="goHome"
      >
        <img
          :src="imgList.close"
          alt=""
        >
        <span>关闭页面</span>
      </div>
      <div
        class="icon-btn"
        @click="goHome"
      >
        <img
          :src="imgList.home"
          alt=""
        >
        <span>回到首页</span>
      </div>
    </div>
  </div>
</template>
<script>
import ApplyDemo from "@/components/common/ApplyDemo.vue";
export default {
  name: "views-applyPage",
  components: { ApplyDemo },
  data() {
    return {
      imgList: {
        logo: require("@/assets/images/w-logo.png"),
        close: require("@/assets/images/tanBox-x.png"),
        home: require("@/assets/images/rthome.png"),
      },
    };
  },
  methods: {
    goHome() {
      this.$router.push({ name: "home" });
    },
  },
};
</script>
<style lang="less" scoped>
.views-applyPage {
  height: 100%;
  .logo {
    position: absolute;
    top: 40px;
    left: 65px;
  }
  .icon-group {
    position: absolute;
    top: 60px;
    right: 80px;
    font-size: 16px;
    color: #333;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .icon-btn {
      display: flex;
      justify-content: space-around;
      align-items: center;
      cursor: pointer;
      & + .icon-btn {
        margin-left: 25px;
      }
    }
    img {
      margin-right: 10px;
      position: relative;
      top: -1px;
    }
  }
}
@media screen and (max-width: 1600px) {
  .views-applyPage {
    .logo {
      height: 50px;
    }
    .icon-group {
      font-size: 14px;
      right: 50px;
    }
  }
}
@media screen and (max-width: 1500px) {
  .views-applyPage {
    .logo {
      top: 25px;
      left: 30px;
      height: 40px;
    }
    .icon-group {
      top: 25px;
      right: 30px;
    }
  }
}
@media screen and (max-width: 992px) and (orientation: portrait) {
  .views-applyPage {
    .icon-group {
      .icon-btn {
        color: #fff;
      }
    }
    /deep/ .apply-demo {
      .left-wrapper {
        padding-top: 70px;
      }
    }
  }
}
@media screen and (max-width: 680px) and (orientation: portrait) {
  .views-applyPage {
    .logo {
      height: 30px;
    }
    .icon-group {
      font-size: 0;
      img {
        margin-right: 0;
      }
    }
  }
}
</style>